.loaderRoot {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    margin: auto;
    background: url("/src/images/rb_3309.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.loader {
    text-align: center;
    display: flex;
    margin: auto;
}

.loader p {
    display: block;
    margin: auto;
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    text-shadow: 0px 0px 4px #000000;
    color: #474747;
}

.loader[hover='true'] p {
    font-size: 40px;
}